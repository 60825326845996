import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		cartProducts: [],
		voucherCodeId: null,
	},
	plugins: [
		createPersistedState({
			key: ['cartProducts'],
		}),
	],
	mutations: {
		ADD_TO_CART_PRODUCTS(state, product) {
			state.cartProducts = [product, ...state.cartProducts]
		},
		CLEAR_CART_PRODUCTS(state) {
			state.cartProducts = []
		},
		REMOVE_FROM_CART_PRODUCTS(state, productId) {
			state.cartProducts = state.cartProducts.filter((product) => product.id !== productId)
		},
		INCREASE_PRODUCT_QUANTITY(state, productId) {
			state.cartProducts = state.cartProducts.map((product) => {
				if (product.id !== productId) return product
				return {
					...product,
					quantity: product.quantity + 1,
				}
			})
		},
		DECREASE_PRODUCT_QUANTITY(state, productId) {
			state.cartProducts = state.cartProducts.map((product) => {
				if (product.id !== productId) return product

				if (product.quantity <= 1) return product
				return {
					...product,
					quantity: product.quantity - 1,
				}
			})
		},
		SET_VOUCHER_CODE_ID(state, voucherCodeId) {
			state.voucherCodeId = voucherCodeId
		},
		RESET_VOUCHER_CODE_ID(state) {
			state.voucherCodeId = null
		},
	},
})
