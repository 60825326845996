<template>
	<v-app>
		<v-main>
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
export default {
	name: 'App',
	mounted() {
		if (typeof window !== 'undefined') {
			const loaderEl = document.getElementById('global-loader')

			if (loaderEl) {
				if (!loaderEl.classList.contains('hidden')) {
					loaderEl.classList.add('hidden') // hide the loader
				}
			}

			// check on load
			this.$vuetify.theme.dark = window.matchMedia('(prefers-color-scheme: dark)').matches
			// listen on change
			window
				.matchMedia('(prefers-color-scheme: dark)')
				.addEventListener('change', this.handleChangeMedia)
		}
	},
	beforeDestroy() {
		if (typeof window !== 'undefined') {
			window
				.matchMedia('(prefers-color-scheme: dark)')
				.removeEventListener('change', this.handleChangeMedia)
		}
	},
	methods: {
		handleChangeMedia(event) {
			this.$vuetify.theme.dark = event.matches
		},
	},
}
</script>

<style lang="scss">
@import '@/assets/scss/global.scss';

$font-family: 'Inter', 'Roboto', sans-serif;
$black: #000000;
$white: #ffffff;
$app-black: #1e1e1e;

.v-application {
	[class*='text-'] {
		font-family: $font-family !important;
	}
	font-family: $font-family !important;
}

// light theme
.theme--light.v-application {
	.text-theme {
		color: $black;
	}

	.bg-theme {
		background-color: $white;
	}
}

.\!h-85px {
	height: 85px !important;
}

// dark
.v-application .v-text-field--outlined fieldset {
	border-color: rgba($color: #f9fafb, $alpha: 0.4);
	border-width: 2px;
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding-box;
	background-clip: padding-box;
}

// light
.theme--light.v-application .v-text-field--outlined fieldset {
	border-color: currentColor;
}

// dark theme
.v-application {
	.text-theme {
		color: $white;
	}
	.bg-theme {
		background-color: $app-black;
	}
}

/* global transition */
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
