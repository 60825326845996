import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#047857',
				secondary: {
					base: '#7D8FAB',
					lighten2: '#E8ECF2',
				},
			},
			dark: {
				primary: '#047857',
				secondary: {
					base: '#7D8FAB',
					lighten2: '#545353',
				},
			},
		},
		options: {
			customProperties: true,
		},
	},
})
