/* eslint-disable operator-linebreak */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/Home.vue'),
		meta: {
			title: 'Home',
		},
	},
	{
		path: '/unauthorized',
		name: 'unauthorized',
		component: () => import('@/views/Unauthorized.vue'),
		meta: {
			title: 'Unauthorized',
		},
	},
	{
		path: '/products',
		name: 'products',
		component: () => import('@/views/Products.vue'),
		meta: {
			title: 'Products',
		},
	},
	{
		path: '/products/goal/:id',
		name: 'products-goal-details',
		component: () => import('@/views/products-goal/ProductsGoalDetails.vue'),
		meta: {
			title: 'Goal Products',
		},
	},
	{
		path: '/product/:id',
		name: 'product-details',
		component: () => import('@/views/ProductsDetails.vue'),
		meta: {
			title: 'Product',
		},
	},
	{
		path: '/me/orders',
		name: 'my-orders',
		component: () => import('@/views/orders/MyOrders.vue'),
		meta: {
			title: 'My Orders',
		},
	},
	{
		path: '/me/order/:id',
		name: 'my-order-details',
		component: () => import('@/views/orders/MyOrderDetails.vue'),
		meta: {
			title: 'My Order',
		},
	},
	{
		path: '/goals',
		name: 'goals',
		component: () => import('@/views/goal/Goals.vue'),
		meta: {
			title: 'Goals',
		},
	},
	{
		path: '/me',
		name: 'profile',
		component: () => import('@/views/profile/Profile.vue'),
		meta: {
			title: 'My Profile',
		},
	},
	{
		path: '/me/edit',
		name: 'edit-profile',
		component: () => import('@/views/profile/EditProfile.vue'),
		meta: {
			title: 'Edit My Profile',
		},
	},
	{
		path: '/me/password/change',
		name: 'change-password',
		component: () => import('@/views/profile/ChangePassword.vue'),
		meta: {
			title: 'Change Password',
		},
	},
	{
		path: '/me/cart',
		name: 'shopping-cart',
		component: () => import('@/views/ShoppingCart.vue'),
		meta: {
			title: 'Shopping Cart',
		},
	},
	{
		path: '/place-order',
		name: 'place-order',
		component: () => import('@/views/PlaceOrder.vue'),
		meta: {
			title: 'Place Order',
		},
	},
	{
		path: '/checkout',
		name: 'checkout',
		component: () => import('@/views/Checkout.vue'),
		meta: {
			title: 'Checkout',
		},
	},
	{
		path: '/payment/success',
		name: 'payment-success',
		component: () => import('@/views/PaymentSuccess.vue'),
		meta: {
			title: 'Success',
		},
	},
	{
		path: '/me/cart/remove',
		name: 'remove-cart',
		component: () => import('@/views/RemoveCart.vue'),
		meta: {
			title: 'Cart',
		},
	},
	{
		path: '/account-deleted',
		name: 'account-deleted',
		component: () => import('@/views/AccountDeleted.vue'),
		meta: {
			title: 'Account Deleted',
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach((to, _, next) => {
	if (typeof to.meta !== 'undefined') {
		const { title } = to.meta

		if (title) {
			document.title = `${title} | NSquare Herbal`
		}
	}

	// send to unauthorized if page does not have query param token
	// only if page not is unauthorized, remove-cart, products, product details and account-deleted
	if (
		to.name !== 'unauthorized' &&
		to.name !== 'remove-cart' &&
		to.name !== 'account-deleted' &&
		to.name !== 'products' &&
		to.name !== 'product-details' &&
		typeof to.query.token !== 'string'
	) {
		return next({ name: 'unauthorized' })
	}

	// has token but empty
	if (typeof to.query.token === 'string') {
		const { token } = to.query
		if (!token) {
			return next({ name: 'unauthorized' })
		}
	}

	return next()
})

export default router
